import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import Workflow from '../Workflow/index'
import './UserInput.css'

export default function MultiActionAreaCard() {

  const[workflow,setWorkflow] = React.useState(false)

  const showWorkflow = () => {
    setWorkflow(true);
  }

  const hideWorkflow=()=>{
    setWorkflow(false);
  }

  return (
    <Card className='card'>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Quickube Recommendations
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            By enter scan id and token (optional) you can get the Recommendations for your cluster.
          </Typography>
        </CardContent>
        <TextField className='scanId' id="outlined-basic" label="scan id" variant="outlined" />
        <TextField className='token' id="outlined-basic" label="token" variant="outlined" />
      </CardActionArea>
      <CardActions>
        {!workflow&&
          <Button className='recommandButton' onClick={showWorkflow} size="large" color="primary">
            Get Recommendations
          </Button>
        }
      </CardActions>
      {workflow&&
      <div>
        <Workflow className='workflow'/>
        <Button className='resetButton' onClick={hideWorkflow} size="large" color="primary">
          Reset
        </Button>
      </div>
      }
    </Card>
  );
}
