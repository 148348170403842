import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Workflow.css'
import CircularWithValueLabel from '../Optimize/index'

function createData(workload,pods,currentCpu,currentMemory,recommendedCpu,recommendedMemory) {
  return {workload,pods,currentCpu,currentMemory,recommendedCpu,recommendedMemory };
}

const rows = [
  createData('Workload 1', 'pod-j,pod-x,pod-e', 0.1, 500, 0.1,300),
  createData('Workload 2', 'pod-ewx,pod-qwe', 0.5, 1500, 0.3,800),
  createData('Workload 3', 'pod-pp12,pod-ert', 0.3, 700, 0.2,256),
  createData('Workload 4', 'pod-wqer,pod-poi', 2, 300, 1,300),
  createData('Workload 5', 'pod-ewr3,pod-sx,pod-jw', 0.8, 900, 0.5,500),
];

const Optimize = (current,recommended) => {
  return 100 - (recommended/current) * 100
}

export default function Workflow() {
  return (
    <TableContainer className='page' component={Paper}>
      <Table className='workflowTable'>
        <TableHead>
          <TableRow>
            <TableCell>Workload</TableCell>
            <TableCell align="right">pods</TableCell>
            <TableCell align="right">current cpu</TableCell>
            <TableCell align="right">current memory</TableCell>
            <TableCell align="right">recommended cpu</TableCell>
            <TableCell align="right">recommended memory</TableCell>
            <TableCell align="right">memory optimize</TableCell>
            <TableCell align="right">cpu optimize</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.workload}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.workload}
              </TableCell>
              <TableCell align="right">{row.pods}</TableCell>
              <TableCell align="right">{row.currentCpu}</TableCell>
              <TableCell align="right">{row.currentMemory}</TableCell>
              <TableCell align="right">{row.recommendedCpu}</TableCell>
              <TableCell align="right">{row.recommendedMemory}</TableCell>
              <TableCell align="right">
                <CircularWithValueLabel value={Optimize(row.currentMemory,row.recommendedMemory)}/>
              </TableCell>
              <TableCell align="right">
                <CircularWithValueLabel value={Optimize(row.currentCpu,row.recommendedCpu)}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
